/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.bg,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}